import Markdown from "markdown-to-jsx";
import { useTranslation } from "react-i18next";

import { A } from "../features/shared/A";
import { Head } from "../features/shared/Head";

import "./MaintenancePage.scss";
import Image from "./MaintenancePageImage.png";

const classNamePrefix = "MaintenancePage";

export const MaintenancePage = () => {
  const { t } = useTranslation();
  const { meta, text, title } = t("pages.MaintenancePage") as unknown as {
    meta: { title: string };
    text: string;
    title: string;
  };

  return (
    <>
      <Head {...meta} />
      <div className={classNamePrefix} role="alert">
        <img
          alt=""
          className={`${classNamePrefix}-logo`}
          height="52"
          src="/app.svg"
          width="180"
        />
        <div className={`${classNamePrefix}-image`}>
          <img alt="" src={Image} />
        </div>
        <div className={`${classNamePrefix}-content`}>
          <h1>{title}</h1>
          <p>
            <Markdown options={{ overrides: { a: A } }}>{text}</Markdown>
          </p>
        </div>
      </div>
    </>
  );
};
