import { Hub, HubCapsule } from "@aws-amplify/core";
import React from "react";
import { useNavigate } from "react-router-dom";

import { privateApi } from "../../app/api/private";
import { router } from "../../app/services/router";
import store from "../../app/store";
import { unsetCurrentUser } from "../../app/store/auth";

export const SessionWatcher = () => {
  const navigateRef = React.useRef(useNavigate());

  React.useEffect(() => {
    const reset = () => {
      store.dispatch(privateApi.util.resetApiState());
      store.dispatch(unsetCurrentUser());

      const navigate = navigateRef.current;
      navigate(router.getTo("home"));
    };

    const onAuth = (data: HubCapsule) => {
      if (data.payload.event === "signOut") {
        reset();
      }
    };
    Hub.listen("auth", onAuth);

    const onStorage = (event: StorageEvent) => {
      if (event.key === "amplify-signin-with-hostedUI" && !event.newValue) {
        reset();
      }
    };
    window.addEventListener("storage", onStorage);

    return () => {
      Hub.remove("auth", onAuth);
      window.removeEventListener("storage", onStorage);
    };
  }, []);

  return null;
};
