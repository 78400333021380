import React from "react";
import { useTranslation } from "react-i18next";

import { session } from "../../app/services/session";
import { useAppSelector } from "../../app/store";
import { selectCurrentUser } from "../../app/store/auth";
import { AlertDialog } from "../shared/AlertDialog";
import { Button } from "../shared/Button";

export const SessionDialog = () => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(session.duration);
  const currentUser = useAppSelector(selectCurrentUser);
  const timerRef = React.useRef(
    (() => {
      let intervalId = 0;

      function start() {
        stop();

        intervalId = window.setInterval(() => tick(), 1000);
        tick();
      }

      function stop() {
        window.clearInterval(intervalId);
      }

      function tick() {
        const nextValue =
          session.duration -
          Math.floor(
            (Date.now() - Number(window.localStorage.getItem("lastActivity"))) /
              1000,
          );
        setOpen(nextValue <= 5 * 60);
        setValue(nextValue);

        if (nextValue <= 0) {
          stop();

          session.signOut();
        }
      }

      return { start, stop };
    })(),
  );
  const onStorageRef = React.useRef((event: StorageEvent) => {
    const timer = timerRef.current;

    if (event.key === "lastActivity") {
      timer.start();
    }
  });
  React.useEffect(() => {
    const reset = () => {
      const event = { key: "lastActivity", newValue: String(Date.now()) };
      window.localStorage.setItem(event.key, event.newValue);
      window.dispatchEvent(new StorageEvent("storage", event));
    };

    const onActivity = () => reset();
    window.addEventListener("click", onActivity);
    window.addEventListener("keypress", onActivity);
    window.addEventListener("touchstart", onActivity);

    reset();

    return () => {
      window.removeEventListener("click", onActivity);
      window.removeEventListener("keypress", onActivity);
      window.removeEventListener("touchstart", onActivity);
    };
  }, []);
  React.useEffect(() => {
    const timer = timerRef.current;
    const onStorage = onStorageRef.current;

    if (currentUser) {
      window.addEventListener("storage", onStorage);
      timer.start();
    } else {
      window.removeEventListener("storage", onStorage);
      timer.stop();
    }

    return () => {
      window.removeEventListener("storage", onStorage);
      timer.stop();
    };
  }, [currentUser]);

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <SessionDialogContent value={value} />
    </AlertDialog>
  );
};

type SessionDialogContentProps = { value: number };

const SessionDialogContent = (props: SessionDialogContentProps) => {
  const { value } = props;

  const { t } = useTranslation();
  const { promptMessage, redirectMessage, title } = t(
    "features.session.SessionDialog",
    { minutes: Math.floor(value / 60), seconds: value % 60 },
  ) as unknown as {
    promptMessage: { cancel: string; text: string };
    redirectMessage: { cancel: string; text: string };
    title: string;
  };

  const { cancel, text } = (() => {
    if (value <= 0) {
      return {
        cancel: { children: redirectMessage.cancel },
        text: redirectMessage.text,
      };
    }

    return {
      cancel: { children: promptMessage.cancel },
      text: promptMessage.text,
    };
  })();

  return (
    <AlertDialog.Content
      cancel={<Button {...cancel} variant="action" />}
      text={text}
      title={title}
      variant="information"
    />
  );
};
