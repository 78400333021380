import React from "react";

import { AppRoutes } from "./AppRoutes";
import { AuthenticationApp } from "./AuthenticationApp";
import { useGetMaintenanceQuery } from "./app/api/public/app";
import { useAutoSignInQuery } from "./app/api/public/auth";
import { useAppSelector } from "./app/store";
import { selectCurrentUser } from "./app/store/auth";
import { ScrollRestorer } from "./features/core/ScrollRestorer";
import { Theme } from "./features/core/Theme";
import { Tracking } from "./features/core/Tracking";
import { InformationNoticeDialog } from "./features/informationNotice/InformationNoticeDialog";
import { SessionDialog } from "./features/session/SessionDialog";
import { SessionWatcher } from "./features/session/SessionWatcher";
import { Error500Page } from "./pages/Error500Page";
import { MaintenancePage } from "./pages/MaintenancePage";

const MainApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./MainApp").then((module) => ({
    default: module.MainApp,
  })),
);

export const App = () => {
  const { isError, isLoading, isSuccess } = useAutoSignInQuery();
  React.useEffect(() => {
    if (!isLoading) {
      document.getElementById("splash-screen")?.remove();
    }
  }, [isLoading]);

  const { currentData: maintenance } = useGetMaintenanceQuery(undefined, {
    pollingInterval: 1 * 60 * 1000,
    skip: !isSuccess,
  });

  return (
    <>
      {(() => {
        if (maintenance?.enabled) {
          return <MaintenancePage />;
        }

        if (isError) {
          return <Error500Page />;
        }

        if (isSuccess) {
          return (
            <>
              <AppLayout />
              <AppRoutes />
              <ScrollRestorer />
              <Theme />
              <Tracking />
              <InformationNoticeDialog />
              <SessionDialog />
            </>
          );
        }

        return null;
      })()}
      <SessionWatcher />
    </>
  );
};

const AppLayout = () => {
  const currentUser = useAppSelector(selectCurrentUser);

  return currentUser?.hasAcceptedTermsOfService ? (
    <React.Suspense fallback={null}>
      <MainApp />
    </React.Suspense>
  ) : (
    <AuthenticationApp />
  );
};
